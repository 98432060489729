<template>

    <div class="guided_tour" >

        <!--................step 1.......................-->

        <div v-if="getGuidedTour.step1" class="guided_tour_inner center_step dashbhard_step_1">
            <div class="guided_tour_relative">

                <div class="tour_content">
                    <img src="/img/cat.svg" alt="">
                    <p class="intro">Welcome <span>{{getProfile.firstname}}</span></p>
                    <h3>You are now on the <strong>Dashboard</strong> screen.</h3>
                    <p>This is where you get an overview of your account and see recent activity.</p>
                </div>
                <div class="tour_footer">
                    <div class="left_block">
                        <button class="btn orange skip_tour_btn" @click="stepCall('skip','one')">Skip</button>
                    </div>
                    <div class="right_block">
                        <button class="btn blue btnNext_tour" @click="stepCall('next','one')">Next</button>
                    </div>
                </div>
            </div>

        </div>

        <!--................step 2.......................-->

        <div v-if="getGuidedTour.step2" class="guided_tour_inner dashbhard_step_2 caret_arrow arrow_top_center">
            <div class="guided_tour_relative">
                <div class="tour_content">
                    <img src="/img/cat2.svg" class="cat_icon right_view" alt="">
                    <p class="normal_one">
                        On the left is ContentStudio’s main navigation.
                        From here you can switch between different modules such as <strong>Discovery</strong>,
                        <strong>Composer</strong>, <strong>Automation</strong> and <strong>Planner</strong>.
                    </p>
                </div>
                <div class="tour_footer">
                    <div class="left_block">
                        <button class="btn orange skip_tour_btn" @click="stepCall('skip','two')">Skip</button>
                    </div>
                    <div class="right_block">
                        <button class="btn blue btnBack_tour" @click="stepCall('back','two')">Back</button>
                        <button class="btn blue btnNext_tour" @click="stepCall('next','two')">Next</button>
                    </div>

                </div>
            </div>

        </div>

        <!--................step 3.......................-->

        <div v-if="getGuidedTour.step3" class="guided_tour_inner dashbhard_step_3 caret_arrow arrow_right_middle">
            <div class="guided_tour_relative">
                <div class="tour_content">
                    <img src="/img/cat2.svg" class="cat_icon left_view" alt="">
                    <p class="normal_one">
                        On the top right corner, we have secondary options <strong>Notification</strong>,
                        <strong>Latest Changes</strong>, and <strong>Settings</strong>.
                    </p>
                </div>
                <div class="tour_footer">
                    <div class="left_block">
                        <button class="btn gray skip_tour_btn" @click="stepCall('skip','three')">Skip</button>
                    </div>
                    <div class="right_block">
                        <button class="btn blue btnBack_tour" @click="stepCall('back','three')">Back</button>
                        <button class="btn blue btnNext_tour" @click="stepCall('next','three')">Next</button>
                    </div>
                </div>
            </div>
        </div>

        <!--................ step 4.......................-->

        <div v-if="getGuidedTour.step4" class="guided_tour_inner brand_step_1 caret_arrow arrow_right_middle">
            <div class="guided_tour_relative">
                <div class="tour_content">
                    <img src="/img/cat2.svg" class="cat_icon left_view" alt="">
                    <p class="normal_one">
                        4 On the top right corner, we have secondary options <strong>Notification</strong>,
                        <strong>Latest Changes</strong>, and <strong>Settings</strong>.
                    </p>
                </div>
                <div class="tour_footer">
                    <div class="left_block">
                        <button class="btn gray skip_tour_btn" @click="stepCall('skip','four')">Skip</button>
                    </div>
                    <div class="right_block">
                        <button class="btn blue btnBack_tour" @click="stepCall('back','four')">Back</button>
                        <button class="btn blue btnNext_tour" @click="stepCall('next','four')">Next</button>
                    </div>
                </div>
            </div>
        </div>

        <!--................  step 5.......................-->

        <div v-if="getGuidedTour.step5" class="guided_tour_inner brand_step_2 caret_arrow arrow_bottom_center">
            <div class="guided_tour_relative">
                <div class="tour_content">
                    <img src="/img/cat2.svg" class="cat_icon left_view" alt="">
                    <p class="normal_one">
                        5 On the top right corner, we have secondary options <strong>Notification</strong>,
                        <strong>Latest Changes</strong>, and <strong>Settings</strong>.
                    </p>
                </div>
                <div class="tour_footer">
                    <div class="left_block">
                        <button class="btn gray skip_tour_btn" @click="stepCall('skip','five')">Skip</button>
                    </div>
                    <div class="right_block">
                        <button class="btn blue btnBack_tour" @click="stepCall('back','five')">Back</button>
                        <button class="btn blue btnNext_tour" @click="stepCall('next','five')">Next</button>
                    </div>
                </div>
            </div>
        </div>

        <!--................  step 6.......................-->

        <div v-if="getGuidedTour.step6" class="guided_tour_inner brand_step_3 caret_arrow arrow_right_middle">
            <div class="guided_tour_relative">
                <div class="tour_content">
                    <img src="/img/cat2.svg" class="cat_icon left_view" alt="">
                    <p class="normal_one">
                       6 On the top right corner, we have secondary options <strong>Notification</strong>,
                        <strong>Latest Changes</strong>, and <strong>Settings</strong>.
                    </p>
                </div>
                <div class="tour_footer">
                    <div class="left_block">
                        <button class="btn gray skip_tour_btn" @click="stepCall('skip','six')">Skip</button>
                    </div>
                    <div class="right_block">
                        <button class="btn blue btnBack_tour" @click="stepCall('back','six')">Back</button>
                        <button class="btn blue btnNext_tour" @click="stepCall('next','six')">Next</button>
                    </div>
                </div>
            </div>
        </div>

        <!--................  step 7.......................-->
        <div v-if="getGuidedTour.step7" class="guided_tour_inner brand_step_4 caret_arrow arrow_left_middle">
            <div class="guided_tour_relative">
                <div class="tour_content">
                    <img src="/img/cat2.svg" class="cat_icon right_view" alt="">
                    <p class="normal_one">
                      7 On the top right corner, we have secondary options <strong>Notification</strong>,
                        <strong>Latest Changes</strong>, and <strong>Settings</strong>.
                    </p>
                </div>
                <div class="tour_footer">
                    <div class="left_block">
                        <button class="btn gray skip_tour_btn" @click="stepCall('skip','seven')">Skip</button>
                    </div>
                    <div class="right_block">
                        <button class="btn blue btnBack_tour" @click="stepCall('back','seven')">Back</button>
                        <button class="btn blue btnNext_tour" @click="stepCall('next','seven')">Next</button>
                    </div>
                </div>
            </div>
        </div>

        <!--................  step 8.......................-->

        <div v-if="getGuidedTour.step8" class="guided_tour_inner brand_step_5 caret_arrow arrow_right_middle">
            <div class="guided_tour_relative">
                <div class="tour_content">
                    <img src="/img/cat2.svg" class="cat_icon left_view" alt="">
                    <p class="normal_one">
                        8 On the top right corner, we have secondary options <strong>Notification</strong>,
                        <strong>Latest Changes</strong>, and <strong>Settings</strong>.
                    </p>
                </div>
                <div class="tour_footer">
                    <div class="left_block">
                        <button class="btn gray skip_tour_btn" @click="stepCall('skip','eight')">Skip</button>
                    </div>
                    <div class="right_block">
                        <button class="btn blue btnBack_tour" @click="stepCall('back','eight')">Back</button>
                        <button class="btn blue btnNext_tour" @click="stepCall('next','eight')">Next</button>
                    </div>
                </div>
            </div>
        </div>

        <!--................  step 9.......................-->

        <div v-if="getGuidedTour.step9" class="guided_tour_inner brand_step_6 caret_arrow arrow_left_middle">
            <div class="guided_tour_relative">
                <div class="tour_content">
                    <img src="/img/cat2.svg" class="cat_icon right_view" alt="">
                    <p class="normal_one">
                        9 On the top right corner, we have secondary options <strong>Notification</strong>,
                        <strong>Latest Changes</strong>, and <strong>Settings</strong>.
                    </p>
                </div>
                <div class="tour_footer">
                    <div class="left_block">
                        <button class="btn gray skip_tour_btn" @click="stepCall('skip','nine')">Skip</button>
                    </div>
                    <div class="right_block">
                        <button class="btn blue btnBack_tour" @click="stepCall('back','nine')">Back</button>
                        <button class="btn blue btnNext_tour" @click="stepCall('next','nine')">Next</button>
                    </div>
                </div>
            </div>
        </div>

        <!--................  step 10......................-->

        <div v-if="getGuidedTour.step10" class="guided_tour_inner brand_step_7 caret_arrow arrow_right_middle">
            <div class="guided_tour_relative">
                <div class="tour_content">
                    <img src="/img/cat2.svg" class="cat_icon left_view" alt="">
                    <p class="normal_one">
                        10 On the top right corner, we have secondary options <strong>Notification</strong>,
                        <strong>Latest Changes</strong>, and <strong>Settings</strong>.
                    </p>
                </div>
                <div class="tour_footer">
                    <div class="left_block">
                        <button class="btn gray skip_tour_btn" @click="stepCall('skip','ten')">Skip</button>
                    </div>
                    <div class="right_block">
                        <button class="btn blue btnBack_tour" @click="stepCall('back','ten')">Back</button>
                        <button class="btn blue btnNext_tour" @click="stepCall('next','ten')">Next</button>
                    </div>
                </div>
            </div>
        </div>

        <!--................  step 11......................-->

        <div v-if="getGuidedTour.step11" class="guided_tour_inner brand_step_8 caret_arrow arrow_bottom_center">
            <div class="guided_tour_relative">
                <div class="tour_content">
                    <img src="/img/cat2.svg" class="cat_icon left_view" alt="">
                    <p class="normal_one">
                        11 On the top right corner, we have secondary options <strong>Notification</strong>,
                        <strong>Latest Changes</strong>, and <strong>Settings</strong>.
                    </p>
                </div>
                <div class="tour_footer">
                    <div class="left_block">
                        <button class="btn gray skip_tour_btn" @click="stepCall('skip','eleven')">Skip</button>
                    </div>
                    <div class="right_block">
                        <button class="btn blue btnBack_tour" @click="stepCall('back','eleven')">Back</button>
                        <button class="btn blue btnNext_tour" @click="stepCall('next','eleven')">Next</button>
                    </div>
                </div>
            </div>
        </div>

        <!--................  step 12......................-->

        <div v-if="getGuidedTour.step12" class="guided_tour_inner brand_step_9 caret_arrow arrow_bottom_center">
            <div class="guided_tour_relative">
                <div class="tour_content">
                    <img src="/img/cat2.svg" class="cat_icon left_view" alt="">
                    <p class="normal_one">
                        12 On the top right corner, we have secondary options <strong>Notification</strong>,
                        <strong>Latest Changes</strong>, and <strong>Settings</strong>.
                    </p>
                </div>
                <div class="tour_footer">

                    <div class="left_block">
                        <button class="btn blue btnNext_tour btnEnd_tour" @click="stepCall('finish','twelve')">Let's do it!</button>
                    </div>
                    <div class="right_block">
                        <button class="btn blue btnBack_tour" @click="stepCall('back','twelve')">Back</button>

                    </div>

                </div>
            </div>
        </div>

    </div>

</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {

  data () {
    return {

    }
  },
  created () {

    //            this.setGuidedTourOverlay(true);

  },
  mounted () {},

  computed: {
    ...mapGetters(['getGuidedTour'])
  },
  methods: {
    ...mapActions(['setGuidedTourOverlay'])

  }
}
</script>
