<template>
    <div>
         <div class="checkbox_input_image mt-3" v-if="getCampaignAdd.email_service && getCampaignAdd.type === 'form'">
                <input type="checkbox" name=""
                       v-model="getCampaignAdd.email_field_name"
                       id="showNameField">
                <label for="showNameField" class="checkbox_left">Enable Name Field</label>


         </div>
         <div class="checkbox_input_image mt-3 w-100" v-if="getCampaignAdd.email_service && getCampaignAdd.type=='form'">
             <input type="checkbox" name=""
               v-model="getCampaignAdd.redirect_form_submission"
               id="redirect_form_submission">
            <label  for="redirect_form_submission" class="checkbox_left">Redirect after form submission</label>

           <div v-if="getCampaignAdd.redirect_form_submission" class="mt-3 input_icon_text d-flex align-items-center ">
             <div class="content">
               <div class="url">Redirect URL</div>
               <div class="name">
                 <div class="validation_input">
                   <input type="text" max="50" v-model="getCampaignAdd.redirect_after_submission"
                          id="name"
                          placeholder="Enter Redirect URL"
                          @blur="getCampaignAdd.redirect_after_submission = focusoutConcatenation(getCampaignAdd.redirect_after_submission)"
                         >
                   <span class="input-error" v-if="getCampaignValidations.validations.redirect_after_submission" >{{getCampaignValidations.messages.redirect_after_submission}}</span>
                   <span class="input-error" v-else-if="getCampaignValidations.validations.valid_redirect_after_submission">{{getCampaignValidations.messages.valid_redirect_after_submission}}</span>
                 </div>
               </div>
             </div>
           </div>

        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default ({
  computed: {
    ...mapGetters(['getCampaignAdd','getCampaignValidations'])
  }
})
</script>
