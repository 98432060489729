<template>
    <b-dropdown v-if="!$parent.isTagProvider && getCampaignAdd.type === 'form' && getCampaignAdd.email_list.id" right
                class="  dropdown-menu-right default_style_dropdown mt-3 " :no-caret="true" ref="emailListDropdown">
        <div class="dropdown_header rounded_shape d-flex align-items-center  " slot="button-content"
             :class="{'input-field-error': getCampaignValidations.validations.provider_id}">
                 <span class="text">
                    <template v-if="getCampaignAdd.email_list.id">
                    {{getCampaignAdd.email_list.name}}</template>
                <template v-else>Select an option</template>
                                </span>
            <span class="arrow_icon ml-auto">
                        <i class=" fal fa-angle-down ml-3"></i>
                     </span>
        </div>
        <ul class="inner ">
            <li class="no_text_li" v-if="$parent.lists && $parent.lists.length === 0">
                You do not have connected any list, please connect your list for your account.
            </li>
            <li v-else v-for="list in $parent.lists" @click.prevent="selectEmailList(list)" class="list_item_li">
                {{list.name}}
            </li>

        </ul>
    </b-dropdown>
</template>
<script>
import { mapGetters } from 'vuex'
import Dropdown from '@/ui/Dropdown.vue'
import { campaignTypes } from '@/state/modules/mutation-types'

export default ({
  components: {
    Dropdown
  },
  computed:
      {
        ...mapGetters(['getCampaignAdd', 'getCampaignValidations'])
      },
  methods: {
    selectEmailList (list, hideDropdown = true) {
      this.$store.commit(campaignTypes.SET_CAMPAIGN_ADD_EMAIL_LIST, {
        id: list.id,
        name: list.name
      })
      if (hideDropdown) {
        this.$refs.emailListDropdown.visible = false
      }
    }
  }
})
</script>
