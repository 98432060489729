<template>
  <div v-if="getCampaignAdd.type === 'form'" class=" mt-3 input_field editor_main_box">
    <!--<label for="">Agreement Checkbox</label>-->
    <!--<div class="clearfix"></div>-->

    <button @click.prevent="addEditor()" type="button" class="w-100 d-block btn---cta btn-border btn-round  btn-bold">
      <span>Add checkbox</span>
    </button>
    <div v-if="getCampaignAdd.agreement.length" class="editor_body mt-3">
      <!--<template v-for="(editor,index) in editorArray">-->
      <!--<medium-editor :text='editorArray[index].text' :options='options'-->
      <!--v-on:edit='processEditOperation($event,index)' custom-tag='div'>-->
      <!--</medium-editor>-->

      <!--{{editorArray[index].text}}-->
      <!--</template>-->

      <template v-for="(editor,index) in getCampaignAdd.agreement">
        <div class="editor_item">
          <div class="align_center heading_top">
            <div class="edit_checkbox d-flex" v-if="editor.editName">
              <input type="text" @input="checkValidation(editor,'name')" v-model="editor.name"
                     placeholder="Enter Name...">
              <div v-if="editor.validations.name || editor.validations.nameLength" class="icon danger">
                <i class="fal fa-exclamation-triangle"></i>
              </div>
              <div v-else class="icon success cursor_pointer" @click.prevent="editAgreementNameSave(index)">
                <i class="far fa-check"></i>
              </div>
            </div>
            <p v-else>{{ editor.name }}</p>

            <div class="action_btn">
              <i v-if="!editor.editName" class="far fa-pencil"
                 @click.prevent="editAgreementName(index)"></i>
              <i class="far fa-trash" @click.prevent="deleteAgreement(index)"></i>
              <i :class="`far fa-angle-${editor.toggle ? 'up' : 'down'}`" @click="toggleBox($event,index)"></i>
            </div>
          </div>
          <span class="input-error" v-if="editor.validations.name">{{ agreementMessages.name }}</span>
          <span class="input-error" v-else-if="editor.validations.nameLength">{{ agreementMessages.nameLength }}</span>
          <div class="toggle_body" v-if="editor.toggle">

            <vue-editor class="agreement-text-editor" @input="checkValidation(editor)" :id="'editor_'+index"
                        v-model="editor.text"
                        :editorToolbar="customToolbar"></vue-editor>
            <span class="input-error" v-if="editor.validations.text">{{ agreementMessages.text }}</span>
            <span class="input-error" v-else-if="editor.validations.textLength">{{
                agreementMessages.textLength
              }}</span>
            <br>
            <label class="switch" :for="'optional_field_'+index">
              <input type="checkbox" :id="'optional_field_'+index"
                     :checked="editor.optional_field" @change="editorSwitch(index)">
              <div class="slider round"></div>
            </label>
            <label style="margin-left: 2px;font-size: 14px;" for="">Optional
              Field</label>

          </div>

        </div>
      </template>

    </div>

  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { VueEditor } from 'vue2-editor'
import { agreementValidations, agreementMessages } from '@/common/attributes'

export default ({
  components: {
    VueEditor
  },
  data () {
    return {
      customToolbar: [
        [
          'bold',
          'italic',
          'underline',
          'link',
          { 'color': [] },
          { 'list': 'ordered' },
          { 'list': 'bullet' }
        ],
        ['customControl']
      ],
      agreementMessages: agreementMessages
    }
  },
  created () {
    this.getCampaignAdd.agreement = this.getCampaignAdd.agreement.map(val => ({
      ...val,
      validations: { ...agreementValidations }
    }))
  },
  computed: {
    ...mapGetters(['getCampaignAdd'])
  },
  methods: {
    addEditor () {
      if (this.getCampaignAdd.agreement.length >= 10) return this.$store.dispatch('toastNotification', {
        message: 'Maximum 10 checkboxes are allowed.',
        type: 'error'
      })
      let total = 1
      if (this.getCampaignAdd.agreement) {
        total = this.getCampaignAdd.agreement.length + 1
      }
      let arrayItem = {
        id: Math.floor(Math.random() * (99999999 - 1 + 1)),
        text: '<p>I accept the <a href=\'\'>Terms</a> and the <a href=\'\'>Privacy Policy</a>.</p>',
        toggle: true,
        name: 'Agreement Checkbox ' + total,
        optional_field: false,
        editName: false,
        validations: { ...agreementValidations }
      }
      this.getCampaignAdd.agreement.push(arrayItem)
    },
    toggleBox (event, index) {
      this.getCampaignAdd.agreement[index].toggle = !this.getCampaignAdd.agreement[index].toggle
    },
    editorSwitch (index) {
      console.debug('here')
      this.getCampaignAdd.agreement[index].optional_field =
        !this.getCampaignAdd.agreement[index].optional_field
    },

    deleteAgreement (index) {
      this.getCampaignAdd.agreement.splice(index, 1)
      // this.getCampaignAdd.agreement.splice(index, 1)
    },
    editAgreementName (index) {
      this.getCampaignAdd.agreement[index].editName = true
    },
    editAgreementNameSave (index) {
      this.getCampaignAdd.agreement[index].editName = false
    },
    checkValidation (agreementObj, called = '') {
      if (called === 'name') {
        const name = agreementObj.name
        agreementObj.validations.name = this.requiredCheck(name)
        if (name && name.length > 0) {
          agreementObj.validations.nameLength = !this.maxLength(name, 100)
        }
        return
      }
      const text = agreementObj.text
      agreementObj.validations.text = this.requiredCheck(text)
      if (text && text.length > 0) {
        agreementObj.validations.textLength = !this.maxLength(text, 500)
      }
    }
  }
})
</script>

<style lang="less" scoped>
.agreement-text-editor {
  height: 200px !important;
  margin-bottom: 34px !important;
}

.edit_checkbox {
  background: rgb(224 239 255) !important;
  border-radius: 30px;

  input {
    background: transparent !important;
    border-radius: 0 !important;
    border-right: 1px solid #ccc !important;
  }

  .icon {
    padding: 10px;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    transition: 0.5s;
  }

  .success {
    &:hover {
      transition: 0.5s;
      background: #3988fe;

      i {
        color: #ffffff;
      }

    }

    i {
      color: #2dd678;
    }
  }

  .danger {
    &:hover {
      transition: 0.5s;
      background: #f2405d;

      i {
        color: #ffffff;
      }

    }

    i {
      color: #f2405d;
    }
  }
}
</style>
