<template>

  <div class="cta_main_view w-100">

    <div :class="{'activeTour' : getGuidedTour.step7}" class="view_inner d-flex flex-column">

      <div class="view_center basic_form">
        <div class="steps_dot mb-3">
          <span class="dotted_circle active"></span>
          <span class="dotted_circle active"></span>
          <span class="dotted_circle active"></span>
          <span class="dotted_circle"></span>
          <span class="dotted_circle"></span>
          <span class="dotted_circle"></span>
          <span class="dotted_circle" v-if="getCampaignAdd.campaign_type.retargeting"></span>
        </div>
        <div class="choice-boxes d-flex align-content-center row">
          <div class="choice-box" :class="{'active': getCampaignAdd.type === 'button'}">
            <div class="box-inner" @click.prevent="$store.commit('SET_CAMPAIGN_ADD_TYPE', 'button')">
              <!--<div class="selection">-->
              <!--<i v-if="getCampaignAdd.type === 'button'" class="fal fa-check-circle"></i>-->
              <!--<i v-else class="fal fa-circle"></i>-->
              <!--</div>-->

              <i class="type-icon fal fa-stream"></i>
              <p data-cy="cta-button">Button</p>
            </div>
          </div>
          <div class="choice-box" :class="{'active': getCampaignAdd.type === 'link'}">
            <div class="box-inner" @click.prevent="$store.commit('SET_CAMPAIGN_ADD_TYPE', 'link')">
              <!--<div class="selection">-->
              <!--<i v-if="getCampaignAdd.type === 'link'" class="fal fa-check-circle"></i>-->
              <!--<i v-else class="fal fa-circle"></i>-->
              <!--</div>-->
              <i class="type-icon fal fa-link"></i>
              <p data-cy="cta-link">Link</p>
            </div>
          </div>
          <div class="choice-box" :class="{'active': getCampaignAdd.type === 'form'}">
            <div class="box-inner" @click.prevent="$store.commit('SET_CAMPAIGN_ADD_TYPE', 'form')">
              <!--<div class="selection">-->
              <!--<i v-if="getCampaignAdd.type === 'form'" class="fal fa-check-circle"></i>-->
              <!--<i v-else class="fal fa-circle"></i>-->
              <!--</div>-->
              <i class="type-icon fal fa-file-alt"></i>
              <p data-cy="cta-form">Form</p>
            </div>
          </div>
        </div>


        <!-- Campaign dropdown -->
        <b-dropdown ref="cta_type_dropdown" right
                    class="createLink_dropdown_dashboard radio_dropdown dropdown-menu-right default_style_dropdown"
                    :no-caret="true">
          <div class="dropdown_header with-shadow d-flex align-items-center rounded_shape" slot="button-content">
            <span class="text">
              <p v-if="!getCampaignAdd.cta_type">Select your cta type</p>
              <p class="text-capitalize" v-else>{{ getCTATypeName }}</p>
            </span>
            <span class="arrow_icon ml-auto"><i class=" fal fa-angle-down ml-3"></i></span>
          </div>
          <ul class="clear inner">
            <li v-for="cta in ctaTypes"
                @click.prevent="getCampaignAdd.cta_type = cta.key,$refs.cta_type_dropdown.hide(true)"
                class="list_item_li">{{ cta.name }}
              <span v-if="cta.key === 'bridge_cta'" class="bridge_cta_badge ml-1">New!</span>
            </li>
          </ul>
        </b-dropdown>

        <upload-image-or-url
          :imageUrl="getCampaignAdd.image"
          :validations="URLValidations"
          @uploadImage="uploadImage($event)"
          title="Call-To-Action (CTA) Image"
          urlTitle="Upload Call-To-Action (CTA) image url"
          placeholder="Call-To-Action (CTA) image url"
          ref="upload_image_or_url"
        ></upload-image-or-url>

        <b-dropdown v-if="getCampaignAdd.type === 'form'" right
                    class="mt-3 dropdown-menu-right default_style_dropdown " :no-caret="true"
                    ref="dropdownMenuEmail">
          <div class="dropdown_header rounded_shape d-flex align-items-center  "
               :class="{'input-field-error': getCampaignValidations.validations.provider}"
               slot="button-content">
                        <span class="text" data-cy="select-service">
                           <template v-if="!getCampaignAdd.email_service">Select your Email Service Provider</template>
                            <template v-else>
                                {{ getCampaignAdd.email_service }}
                            </template>
                        </span>
            <span class="arrow_icon ml-auto">
                        <i class=" fal fa-angle-down ml-3"></i>
                     </span>

          </div>
          <ul class="inner ">
            <li class="list_item_li" v-for="provider in email_providers" data-cy="email-service">
              <template v-if="provider.connected">
                <p @click.prevent="selectEmailProvider(provider.internal_name)">
                  {{ provider.name }}
                </p>
              </template>
              <template v-else>
                <p class="disabled_attribute">
                  {{ provider.name }}
                </p>

              </template>

            </li>

          </ul>
        </b-dropdown>

        <template v-if="getCampaignAdd.email_service && getCampaignAdd.type === 'form'">
          <b-dropdown right class="mt-3 dropdown-menu-right default_style_dropdown " ref="dropdownAccountSelect"
                      :no-caret="true">
            <div class="dropdown_header rounded_shape d-flex align-items-center  " slot="button-content"
                 :class="{'input-field-error': getCampaignValidations.validations.provider_id}">
                              <span class="text" data-cy="email-account">
                                   <template v-if="!getCampaignAdd.email_service_id">
                                        <template v-if="getCampaignAdd.email_service === 'convertfox'">
                                            Select a project
                                        </template>
                                        <template v-else>
                                            Select an account
                                        </template>
                                   </template>
                                    <template v-else>
                                        {{ getEmailAccountName }}
                                    </template>
                                </span>
              <span class="arrow_icon ml-auto">
                        <i class=" fal fa-angle-down ml-3"></i>
                     </span>
            </div>
            <ul class="inner ">
              <li class="list_item_li" data-cy="e-account"
                  v-for="account in getEmailProviderAccounts"
                  @click="changeAccountOfServiceProvider(account)"
                  v-if="getEmailProviderAccounts.length > 0">
                <template v-if="account.name">
                  {{ account.name }}
                </template>
                <template v-else>
                  {{ account.account_name }}
                </template>

              </li>
              <li class="no_text_li" v-else>
                No service provider connected yet.
              </li>

            </ul>
          </b-dropdown>
          <span class="input-error" v-if="getCampaignValidations.validations.provider_id">
                        {{ getCampaignValidations.messages.provider_id }}
                    </span>

          <template v-if="getCampaignAdd.email_service_id && isTagProvider">
            <div class="text-center" v-if="getCampaignLoaders.tags">
              <clip-loader :size="size" :color="colorBlue"></clip-loader>
            </div>
            <template v-else>
              <div class="input_icon_text d-flex mt-4 align-items-center ">
                <div class="content">
                  <div class="url">Pass tags to {{ tagProviderText }} (optional)
                    <i class="fal fa-question-circle custom_tooltip tag_tooltip" title="Platform">
                      <div class="tool_tip_box">
                        <div class="inner_box">
                          <p>Passing tags to your email tool will help you easily segment your
                            data. And you will be able to see which leads are coming through
                            your {{ getSiteDetails.agency_name }} promotions.</p>
                        </div>
                      </div>
                    </i>
                  </div>
                  <div class="name">
                    <div class="validation_input">
                      <input type="text"
                             placeholder="We recommend passing ‘replug’ as one of the tags."
                             @focus="addEmailServiceTag($event,getCampaigns.provider.tag_text)"
                             @blur="blurEmailFocusTag()"
                             v-on:keyup="addEmailServiceTag($event,getCampaigns.provider.tag_text)"
                             class="" v-model="getCampaigns.provider.tag_text">
                      <!-- checking if the tag already exists and based on that show the results -->
                      <ul class="tag_list_dd" v-if="getCampaigns.provider.tag_text && getCampaigns.provider.tag_text.length > 0
                                        && checkTagNotExist(getCampaigns.provider.tag_text,getCampaigns.provider.created_tags)">

                        <template v-if="getCampaigns.provider.suggested_tags.length > 0">

                          <li class="new_item heading">
                            <span class="text">Existing tags in {{ tagProviderText }}</span>
                          </li>

                          <li @click.prevent="addTagToEmailServiceList(tag)" class="old_item"
                              v-for="tag in getCampaigns.provider.suggested_tags">
                            <span class="text">{{ tag }}</span>
                          </li>
                        </template>
                        <li v-else class="new_item heading" style="font-weight: normal;">
                                            <span class="text"
                                                  @click.prevent="addEmailServiceTag($event, getCampaigns.provider.tag_text,true)">Add new tag "{{
                                                getCampaigns.provider.tag_text
                                              }}"</span>
                        </li>

                      </ul>
                      <ul class="tag_list_dd"
                          v-else-if="getCampaigns.provider.tag_text && getCampaigns.provider.tag_text.length === 0 && getCampaigns.provider.focus === true">
                        <template v-if="getCampaigns.provider.suggested_tags.length > 0">
                          <li class="new_item heading">
                                        <span class="text">Existing tags in {{ tagProviderText }}
                                        </span>
                          </li>
                          <li class="old_item"
                              v-for="tag in getCampaigns.provider.suggested_tags">
                                                <span class="text"
                                                      @click.prevent="addTagToEmailServiceList(tag)">{{ tag }}</span>
                          </li>
                        </template>
                        <template v-else>

                          <li class="new_item heading" style="font-weight: normal;">
                            <span class="text">No existing tags found.</span>
                          </li>

                        </template>

                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <ul class="tag_list">

                <li v-for="(tag,index) in getCampaignAdd.email_service_tags">
                  <span class="text">{{ tag }}</span><span
                  @click.prevent="$store.commit('REMOVE_CAMPAIGN_ADD_EMAIL_SERVICE_TAG', tag)"
                  class="cross_icon"></span>
                </li>
              </ul>
            </template>

          </template>
        </template>
        <span v-if="getCampaignValidations.validations.provider && getCampaignAdd.type === 'form'"
              class="input-error">
                    {{ getCampaignValidations.messages.provider }}
                </span>

        <div style="display: none;">
          Tag provider: {{ isTagProvider }}
          <br>
          Form type: {{ getCampaignAdd.type }}
          <br>
          Campaign ID: {{ getCampaignAdd.email_list.id }}
        </div>
        <!-- email list selection -->
        <email-list-selection></email-list-selection>
        <!-- agreement form -->
        <agreement-form></agreement-form>
        <!-- name and redirect after form submission form -->
        <additional-form-fields></additional-form-fields>

      </div>

      <div class="view_bottom">

        <div v-if="getGuidedTour.step7" class="view_bottom_inner text-center">
          <button
            class="btn---cta  light-blue btn-round"><span>Go Back</span></button>
          <button
            class="btn---cta btn-blue btn-round"><span>Next</span></button>
        </div>

        <div v-else class="view_bottom_inner text-center">
          <button @click="$router.push({ 'name': 'campaign_type' })"
                  class="btn---cta  light-blue btn-round">
            <span>Go Back</span>
          </button>

          <template>
            <button @click.prevent="handleCampaignTypeAction()"
                    class="btn---cta btn-blue btn-round">
              <span>Next</span></button>
          </template>
        </div>

      </div>
    </div>
  </div>

</template>

<script>

import {mapActions, mapGetters} from 'vuex'

import Dropdown from '@/ui/Dropdown.vue'
import GuidedTour from '@/views/guided-tour/GuidedTour.vue'
import {VueEditor} from 'vue2-editor'
import {defaultEmailProviders, URLValidations} from '@/common/attributes'
import orderBy from 'lodash/orderBy'
import startCase from 'lodash/startCase'
import {campaignTypes} from '@/state/modules/mutation-types'
import AgreementForm from './cta-types-form/AgreementForm'
import AdditionalFormFields from './cta-types-form/AdditionalFormFields'
import EmailListSelection from './cta-types-form/EmailListSelection'
import EventBus from "@/state/event-bus";

export default (
  {
    components: {
      Dropdown,
      GuidedTour,
      VueEditor,
      AgreementForm,
      AdditionalFormFields,
      EmailListSelection,
      'upload-image-or-url': () => import('@/ui/UploadImageOrUrl')
    },

    data () {
      return {
        size: '14px',
        color: '#fff',
        colorBlue: '#168eea',
        editorArray: [],
        lists: [],
        email_providers: defaultEmailProviders(),
        tags_providers_list: ['convertfox', 'mautic', 'drip'],
        ctaTypes: [
          {
            key: 'social',
            name: 'Social (Classic)'
          },
          {
            key: 'social_modern',
            name: 'Social (Modern)'
          },
          {
            key: 'banner',
            name: 'Banner'
          },
          {
            key: 'exit_intent',
            name: 'Exit intent'
          },
          {
            key: 'popup_center',
            name: 'Popup'
          },
          {
            key: 'scroll_box',
            name: 'Scroll Box'
          },
          {
            key: 'bridge_cta',
            name: 'Bridge Page'
          }
        ],
        URLValidations: URLValidations
      }
    },

    created () {
      if (!this.getCampaignAdd.brand_id) return this.$router.push({name: 'create'})
      let profile = this.getProfile
      this.email_providers.forEach(item => {
        if (profile.hasOwnProperty(item.internal_name)) {
          if (profile[item.internal_name].length > 0) item.connected = true
        }
      })
      this.email_providers = orderBy(this.email_providers, ['connected'], ['desc'])
    },
    computed: {

      ...mapGetters([
        'getCampaigns',
        'getCampaignAdd',
        'getCampaignLoaders',
        'getCampaignValidations',
        'getProfile',
        'getDomains'
      ]),
      getEmailAccountName () {
        let campaign = this.getCampaignAdd
        let profile = this.getProfile

        let accountId = campaign.email_service_id
        let provider = campaign.email_service

        let account = profile[provider].find(item => (item.account_id === accountId))
        if (!account) return null
        let name = (account.hasOwnProperty('name')) ? account.name : account.account_name
        return this.limitLengthWithoutDots(name, 28)
      },
      getEmailProviderAccounts () {
        let campaign = this.getCampaignAdd
        const provider = campaign.email_service
        return (this.getProfile.hasOwnProperty(provider)) ? this.getProfile[provider] : []
      },
      isTagProvider () {
        return (this.tags_providers_list.indexOf(this.getCampaignAdd.email_service) >= 0)
      },
      tagProviderText () {
        return startCase(this.getCampaignAdd.email_service)
      },
      getCTATypeName () {
        return this.getCampaignAdd.cta_type.includes('bridge') ? 'Bridge Page' : this.getCampaignAdd.cta_type.split('_').join(' ')
      }
    },

    methods: {
      ...mapActions([
        'fetchProviderTags'
      ]),
      /**
       * Selecting the Accounts Service Provider
       * @param account
       */
      changeAccountOfServiceProvider (account) {
        // Set the email service ID in the store
        this.$store.commit('SET_CAMPAIGN_ADD_EMAIL_SERVICE_ID', account.account_id)

        // Get the email service provider and profile
        let provider = this.getCampaignAdd.email_service
        let profile = this.getProfile


        // If the provider is ConvertFox, show the dropdown for account selection
        if (provider.toLowerCase() === 'convertfox') {
          this.$refs.dropdownAccountSelect.visible = true
          this.$store.commit(campaignTypes.SET_CAMPAIGN_ADD_EMAIL_LIST, account)
          return
        }

        // Reset the provider ID validation and hide the dropdown
        this.$set(this.getCampaignValidations.validations, 'provider_id', false)
        this.$refs.dropdownAccountSelect.visible = false


        // Fetch provider tags for Drip, ConvertFox, and Mautic
        if (['drip', 'convertfox', 'mautic'].includes(provider)) {
          this.fetchProviderTags({account: value, provider: provider})
          return
        }


        // Check if the profile has the provider and if it has any data
        if (profile.hasOwnProperty(provider) && profile[provider].length > 0) {
          // Check if the account has lists
          if (account.hasOwnProperty('lists')) {
            if (account['lists'] && account['lists'].length > 0) {
              // Set the lists and select the first list by default
              this.lists = account['lists']
              this.$store.commit(campaignTypes.SET_CAMPAIGN_ADD_EMAIL_LIST, account['lists'][0])
              // this.selectEmailList(list[0], false)
            }
          }
          return;
        }

        // Set the email list for other cases
        this.$store.commit(campaignTypes.SET_CAMPAIGN_ADD_EMAIL_LIST, account)

      },
      handleCampaignTypeAction () {
        let result = true

        const uploadImageComponent = this.$refs.upload_image_or_url
        if(uploadImageComponent && uploadImageComponent.checkValidation()) return

        if (this.getCampaignAdd.type === 'form') {
          const agreementList = this.getCampaignAdd.agreement.map(item => item.validations)
          const isAgreementValidation = !!agreementList.filter(item => (Object.keys(item).every(k => item[k] === false)) === false).length
          if (isAgreementValidation) return this.$store.dispatch('toastNotification', {
            message: 'Agreement Validation Failed.',
            type: 'error'
          })

        }
        console.log(this.requiredCheck(this.getCampaignAdd.redirect_after_submission))
        if (!this.campaignIntegrationValidation()) this.$router.push({name: 'message'})
      },
      processEditOperation (event) {
        console.debug(event)
        //                    this.editorArray[index].text = event.api.origElements.innerHTML
      },

      selectEmailProvider (service) {
        this.$store.commit(campaignTypes.RESET_CAMPAIGN_PROVIDER)
        this.$store.commit(campaignTypes.SET_CAMPAIGN_ADD_EMAIL_LIST, {id: null, name: null})
        this.$store.commit(campaignTypes.SET_CAMPAIGN_ADD_EMAIL_SERVICE_NAME, service)
        this.$refs.dropdownMenuEmail.visible = false
      },
      blurEmailFocusTag () {
        console.debug('Method:blurEmailFocusTag')
        setTimeout(() => {
          this.$store.commit(campaignTypes.SET_CAMPAIGN_PROVIDER_TAG_FOCUS, false)
        }, 200)
      },

      addEmailServiceTag (event, tags, add = false) {
        // validation
        console.debug('Method::addLinkTag')
        this.$store.commit(campaignTypes.SET_CAMPAIGN_PROVIDER_TAG_FOCUS, true)
        let tag = (tags && tags.length > 0) ? tags.trim() : null
        if (!tag) {
          this.$store.commit(campaignTypes.SET_CAMPAIGN_PROVIDER_TAG_FOCUS, false)
          return
        }

        // add a new tag

        if (add === true || event.key === 'Enter') {
          let find = this.getCampaigns.provider.created_tags.find(item => item.toLowerCase() === tag.toLowerCase())
          if (!find) {
            this.$store.commit(campaignTypes.ADD_TAG_TO_CAMPAIGN_ADD_EMAIL_SERVICE_TAGS, tag)
            this.$store.commit(campaignTypes.ADD_TAG_TO_CAMPAIGN_PROVIDER_EMAIL_TAGS, tag)
          } else {
            if (this.getCampaignAdd.email_service_tags.indexOf(tag) < 0) {
              this.$store.commit(campaignTypes.ADD_TAG_TO_CAMPAIGN_ADD_EMAIL_SERVICE_TAGS, tag)
            }
          }
          this.$store.commit(campaignTypes.SET_CAMPAIGN_PROVIDER_TAG_TEXT, null)
          this.$store.commit(campaignTypes.SET_CAMPAIGN_PROVIDER_SUGGESTED_TAGS, [])
        }

        // return the suggestions for the tags

        let campaignTags = this.getCampaignAdd.email_service_tags

        const suggestedTags = this.getCampaigns.provider.created_tags.filter(item => {
          return (item.toLowerCase().includes(tag.toLowerCase()) && campaignTags.indexOf(item) < 0 && campaignTags.indexOf(item.toLowerCase()) < 0)
        })
        this.$store.commit(campaignTypes.SET_CAMPAIGN_PROVIDER_SUGGESTED_TAGS, suggestedTags)
      },

      addTagToEmailServiceList (tag) {
        this.$store.commit(campaignTypes.ADD_TAG_TO_CAMPAIGN_ADD_EMAIL_SERVICE_TAGS, tag)
        this.$store.commit(campaignTypes.SET_CAMPAIGN_PROVIDER_TAG_TEXT, null)
      },
      uploadImage (event) {
        this.getCampaignAdd.image = event ? event : ''
      }

    },

    watch: {
      'getCampaignAdd.email_service' (value) {
        if (value) this.$set(this.getCampaignValidations.validations, 'provider', false)
      },
      'getCampaignAdd.type' (value) {
        if (value !== 'form') {
          this.$set(this.getCampaignValidations.validations, 'provider', false)
          this.$set(this.getCampaignValidations.validations, 'provider_id', false)
        }
      },
      'getCampaignAdd.cta_type' (value) {
        this.getCampaignAdd.exit_intent_opacity = this.isBridgeCTACampaign() ? 1 : this.getCampaignAdd.exit_intent_opacity
        this.getCampaignAdd.trigger_seconds = this.isBridgeCTACampaign() ? 7 : this.getCampaignAdd.trigger_seconds
      }
    }
  })
</script>
